<template>
    <section>
        <InsideHeader :title="pageData.page_heading" :bredcrumb="breadCrumb" />
        <div class="section login-2 py-5 blog-page ">
            <div class="container">                
                <div class="row">
                    <div class="col-xl-9 col-lg-8 col-md-7" v-if="blogData.length > 0">                       
                        <div class="row blog-media" v-for="(data, index) of blogData" :key="index">
                            <div class="col-xl-6">
                                <div class="blog-left">
                                    <router-link :to="`/blog/${data.id}/post`">
                                        <img :src="filePath+'/'+data.image_name" class="img-fluid" :alt="data.title">
                                    </router-link>
                                    <div class="date-label">{{ data.post_date }}</div>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="blog-right">
                                    <div>
                                        <router-link :to="`/blog/${data.id}/post`">
                                            <h4>{{ data.title }}</h4>
                                        </router-link>
                                        <div v-html="data.short_content"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-9 col-lg-8 col-md-7" v-else>
                        <NoRecord/>
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-5 order-sec" v-if="popularBlogs.length > 0">
                        <div class="blog-sidebar">
                            <div class="theme-card">
                                <h4>{{ pageContent('popular_blog') }}</h4>
                                <ul class="popular-blog">
                                    <li v-for="(data, index) of popularBlogs" :key="index">
                                        <div class="media">
                                            <div class="blog-date">
                                                <span>{{ data.post_day }}</span>
                                                <span>{{ data.post_month+', '+data.post_year }}</span>
                                            </div>
                                            <div class="media-body align-self-center">
                                                <h6>{{ data.title }}</h6>
                                            </div>
                                        </div>
                                        <div v-html="data.content"></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> 
</template>

<script>
import InsideHeader from "@/components/InsideHeader.vue";
import NoRecord from "@/components/NoRecord.vue";
import Translation from '../../../public/translation.json'

export default {
    components: {
        InsideHeader, NoRecord
    },
    data(){
        return {
            breadcrumb: [],
            dir_path: '',
            defaultLang: localStorage.getItem('_store_lang') || 'en',
            pageData: [],
            blogData: [],
            popularBlogs: [],
            filePath: ''
        }
    },   
    methods: {
        pageContent(field){
            return Translation.content[this.defaultLang][field]
        },
        getPageData() {
            let slug = 'blog'
            this.$store.dispatch('getPageData', slug).then(res=>{
               if(res.data.status === true) {
                    this.pageData = res.data.data
                    if(res.data.dir_path) {
                        this.dir_path = res.data.dir_path
                    }
                }
            })
            .catch(err => {
                console.log(err)
                this.$router.push({name: '404'})
            })
        },
        getData(){            
            this.$http.get(`/blog`).then(res=>{                       
                if(res.status === 200 && res.data){
                    if(res.data.data)
                        this.blogData = res.data.data
                    if(res.data.file_path)
                        this.filePath = res.data.file_path
                }
            })
        },
        popularBlog(){            
            this.$http.get(`/blog/popular-blogs`).then(res=>{                       
                if(res.status === 200 && res.data){
                    if(res.data.data)
                        this.popularBlogs = res.data.data
                    
                }
            })
        }
    },    
    computed: {
        breadCrumb(){
            return this.breadcrumb = [
                {title: this.pageContent('home'), path:'/'},
                {title: this.pageContent('blog'), path:'#'}
            ]
        }       
    },
    created: function(){
        this.getPageData()
        this.getData()
        this.popularBlog()
    },
}
</script>